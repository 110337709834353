// Basic Rails Libraries
import '@hotwired/turbo';
import "./controllers/index";

// Core UI Libraries
import * as bootstrap from "bootstrap";
// import '@popperjs/core'
import "./src/jquery";
import SlimSelect from "slim-select";

// Theme
import "./theme/scripts.bundle";
// import "./theme/theme.bundle";

// Theme Dependencies
import SmoothScroll from "smooth-scroll";
import { createPopper } from '@popperjs/core';

/* Intiliaze some Bootstrap functions */
let popoverTriggerList = [].slice.call(
  document.querySelectorAll('[data-bs-toggle="popover"]')
);
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl);
});

/* Initialize window variables */
window.SmoothScroll = SmoothScroll;
window.createPopper = createPopper;
window.SlimSelect = SlimSelect;