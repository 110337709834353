import BaseValidator from './base_validator';

const bannedWords = [
  "Parliament Hill",
  "Colline du Parlement",
  "Royal Canadian Mounted Police",
  "RCMP",
  "Gendarmerie royale du Canada",
  "GRC",
  "United Nations",
  "UN",
  "Nations Unies",
  "ONU",
  "Cooperative",
  "Co-op",
  "Pool",
  "Coopérative",
];

export default class CompanyNameValidator extends BaseValidator {
  specificValidate() {
    console.log(`Checking Name: ${this.value}`);
    if(this.value.length === 0 ) {
      this.fail(`The company must have a name`);
      return false;
    }
    const hasBannedWord = bannedWords.some(word => {
      const regex = new RegExp(`\\b${word.toLowerCase()}\\b`);
      return regex.test(this.value.toLowerCase());
    });
    if (hasBannedWord) {
        console.log(`setting field value to invalid`);
        this.fail(`The company name is invalid, it contains banned words`);
        return false;
    }

    this.pass();
    return true;
  }
}
