import { Controller } from "@hotwired/stimulus";

import CCINValidator from "../validators/ccin_validator";
import CompanyNameValidator from "../validators/company_name_validator";
import DepositAccountNumberValidator from "../validators/deposit_account_number_validator";
import TransitNumberValidator from "../validators/transit_number_validator";
import EmailValidator from "../validators/email_validator";
import NameValidator from "../validators/name_validator";
import PrimaryContactValidator from "../validators/primary_contact_validator";
import PhoneNumberValidator from "../validators/phone_number_validator";

const validators = {
  ccin: CCINValidator,
  company_name: CompanyNameValidator,
  deposit_account_number: DepositAccountNumberValidator,
  transit_number: TransitNumberValidator,
  email: EmailValidator,
  name: NameValidator,
  primary_contact: PrimaryContactValidator,
  phone_number: PhoneNumberValidator,
};

export default class extends Controller {
  static targets = ["validator"];

  connect() {
    this.stepper = window.stepper;
  }

  async submit(event) {
    const allValid = await this.validateStep();
    if (!allValid) {
      event.preventDefault();
    }
  }

  async nextStep() {
    const allValid = await this.validateStep();
    if (allValid) {
      stepper.goNext();
    }
  }

  async validateStep() {
    const currentStepTargets = this.getTargetElementsForStep(this.stepper.getCurrentStepIndex());
    const validations = await Promise.all(currentStepTargets.map(async target => {
      const validatorName = target.dataset.validator;
      const validatorClass = validators[validatorName];
      if (!validatorClass) return true;

      const validator = new validatorClass(target);
      return await validator.validate();
    }));

    return validations.every(valid => valid);
  }

  getTargetElementsForStep(step) {
    return this.validatorTargets.filter(element => element.dataset.step == step)
  }
}
