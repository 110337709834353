import BaseValidator from './base_validator';

export default class CCINValidator extends BaseValidator {
  specificValidate() {
    console.log(`Checking CCIN: ${this.value}`);
    if (!/^\d{8}$/.test(this.value)) {
      this.fail("Invalid ccin format, must be 8 characters");
      return false;
    }
    this.pass();
    return true;
  }
}
