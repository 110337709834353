import BaseValidator from './base_validator';

export default class PhoneNumberValidator extends BaseValidator {
  specificValidate() {
    console.log(`Checking Phone Number: ${this.value}`);
    const pattern = /^(?:\+1|1)?\s?\(?([2-9][0-9]{2})\)?[-.\s]?([2-9][0-9]{2})[-.\s]?([0-9]{4})$/;
    const validAreaCodes = [
      "204", "226", "236", "249", "250", "289", "306", "343", "365", "387", "403",
      "416", "418", "431", "437", "438", "450", "506", "514", "519", "548", "579",
      "581", "587", "604", "613", "639", "647", "672", "705", "709", "778", "780",
      "782", "807", "819", "825", "867", "873", "902", "905"
    ];

    if (!pattern.test(this.value)) {
      this.fail("Invalid phone number format");
      return false;
    }

    const areaCode = this.value.match(pattern)[1];
    if (!validAreaCodes.includes(areaCode)) {
      this.fail("Invalid Canadian area code");
      return false;
    }

    this.pass();
    return true;
  }
}
