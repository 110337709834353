import BaseValidator from './base_validator';

export default class TransitNumberValidator extends BaseValidator {
  validate() {
    console.log(`Checking Transit Number: ${this.value}`);
    const transitNumber = this.value
    if (!this.validLength(transitNumber)) {
      this.fail("Invalid legnth for transit number. Must be 5 digits.");
      return false;
    }
    if (!/^\d+$/.test(this.value)) {
      this.fail("Transit can only contain digits.");
      return false;
    }
    this.pass();
    return true;
  }

  validLength(transitNumber) {
    if (transitNumber.length == 5) {
      return true;
    }
    return false;
  }

}
