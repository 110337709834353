import BaseValidator from './base_validator';

export default class EmailValidator extends BaseValidator {
  specificValidate() {
    console.log(`Checking Email: ${this.value}`);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(this.value)) {
      this.fail("Invalid email format");
      return false;
    }
    this.pass();
    return true;
  }
}
