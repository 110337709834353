// base_validator.js
export default class BaseValidator {
  constructor(element) {
    this.element = element;
  }

  get value() {
    return this.element.value.trim();
  }

  async validate() {
    if (!this.element.required) {
      return true;
    }
    return await this.specificValidate();
  }

  fail(message) {
    this.element.classList.add('is-invalid');
    const errorDivId = `${this.element.id}_error`;
    const errorDiv = document.getElementById(errorDivId);
    if (errorDiv) {
      errorDiv.textContent = message;
    }
  }

  pass() {
    this.element.classList.remove('is-invalid');
  }

  async specificValidate() {
    throw new Error('You must override specificValidate() in the subclass');
  }
}
