import BaseValidator from './base_validator';

export default class DepositAccountNumberValidator extends BaseValidator {
  specificValidate() {
    console.log(`Checking Deposit Account Number: ${this.value}`);
    const accountNumber = this.value
    if(!this.validLength(accountNumber)) {
      this.fail("Invalid legnth for deposit account number. Must be 7-12 characters.");
      return false;
    }
    if (!/^\d+$/.test(this.value)) {
      this.fail("Deposit account number can only contain digits.");
      return false;
    }
    this.pass();
    return true;
  }

  validLength(accountNumber) {
    if(accountNumber.length >= 7 && accountNumber.length <= 12) {
      return true;
    }
    return false;
  }

}
