import BaseValidator from './base_validator';

export default class PrimaryContactValidator extends BaseValidator {
  async specificValidate() {
    console.log(`Checking Primary Contact: ${this.value}`);
    if (!this.isValidEmail()) {
      this.fail("Invalid email format");
      return false;
    }

    const result = await this.passesDatabaseValidations();
    console.log('CHECKING RESULT');

    if (result === false) {
      this.fail("Company User already exists");
      return false;
    }

    this.pass();
    return true;
  }

  isValidEmail() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(this.value)) {
      return true;
    }
    return false;
  }

  async passesDatabaseValidations() {
    const params = new URLSearchParams({
      email: this.value,
    });
    const response = await fetch(`/company_users/check_if_exists?${params.toString()}`);
    const data = await response.json();
    if (data.valid) {
      return true;
    }
    return false;
  }
}

