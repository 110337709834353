import BaseValidator from './base_validator';

export default class NameValidator extends BaseValidator {
  specificValidate() {
    console.log(`Checking Name: ${this.value}`);
    const name = this.value;

    if (name.length === 0) {
      this.fail("Name is required");
      return false;
    }

    if (name.length > 50) {
      this.fail("Name cannot be longer than 50 characters");
      return false;
    }
      
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!nameRegex.test(this.value)) {
        this.fail("Name can only have letters");
        return false;
    }
    this.pass();
    return true;
  }
}
