import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="select2"
export default class extends Controller {
  static targets = ["select"];

  connect() {
    this.initSelect2();
  }

  initSelect2() {
    console.log("initializing Select2...");

    var options = { dir: document.body.getAttribute("direction") };
    if (this.selectTarget.getAttribute("data-hide-search") == "true") {
      options.minimumResultsForSearch = Infinity;
    }
    $(this.selectTarget).select2(options);

    console.log("Done!");
  }
}
